































import ComponentNavMenu from '@/components/NavMenu.vue';
import { NavMenu } from '@/types/NavMenu';
import Vue from 'vue';
export default Vue.extend({
  'name': 'LayoutAuthenticated',
  'components': {
    ComponentNavMenu
  },
  data () {
    return {
    };
  },
  'computed': {
    navMenus (): Array<NavMenu> {
      return this.$store.getters.navMenus;
    }
  },
  'methods': {
    logOut () {
      this.$store.commit('resetAuthStatus');
      this.$router.push({
        'name': 'Login'
      });
    }
  }
});
