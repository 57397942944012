





















































import Vue from 'vue';
export default Vue.extend({
  'name': 'pagination-component',
  'props': {
    'value': {
      'type': Number,
      'required': true
    },
    'totalPages': {
      'type': Number,
      'required': true
    }
  },
  'computed': {
    hasNextPage () {
      return this.value < this.totalPages;
    },
    hasPreviousPage () {
      return this.value > 1;
    },
    needsTruncate () {
      return this.totalPages > 7;
    },
    needsToShift () {
      const shiftCondition = this.value > 3 && this.value < this.totalPages - 3;
      return this.totalPages > 7 && shiftCondition;
    },
    pagesList () {
      const list = [];

      if (this.needsTruncate) {
        if (this.needsToShift) {
          for (let i = this.value - 3; i < this.value + 4; i++) {
            list.push({
              'display': i + 1,
              'pageNumber': i + 1
            });
          }
        } else if (this.value < 4) {
          for (let i = 0; i < 7; i++) {
            list.push({
              'display': i + 1,
              'pageNumber': i + 1
            });
          }
        } else {
          for (let i = this.totalPages - 7; i < this.totalPages; i++) {
            list.push({
              'display': i + 1,
              'pageNumber': i + 1
            });
          }
        }
      } else {
        for (let i = 0; i < this.totalPages; i++) {
          list.push({
            'display': i + 1,
            'pageNumber': i + 1
          });
        }
      }

      return list;
    }
  },
  'methods': {
    goToPage (pageNumber: number) {
      if (this.value !== pageNumber) {
        this.$emit('input', pageNumber);
      }
    }
  }
});
